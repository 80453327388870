import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DetailsHeader from "../../atoms/DetailsHeader/DetailsHeader";
import Price from "../../atoms/Price/Price";
import Availability from "../../molecules/Availability/Availability";
import Buy from "../Buy/Buy";
import { PRIMARY } from "../../../vars/vars";
import { useAppContext } from "../../../context/AppContext";
import { useConsentContext } from "../../../context/ConsentContext";

const getPrice = (part, defaultStock) =>
  part.price.prices[defaultStock].grossprice;
const isVatIncluded = (part, defaultStock) => {
  const candidate = part && part.price && part.price.prices[defaultStock];
  return candidate.grossprice !== candidate.price;
};

const isDateDifferenceGreaterThan = (deliveryObj, days) => {
  const currentDate = new Date();
  const deliveryDate = new Date(deliveryObj.eta);

  const differenceInMilliseconds = deliveryDate - currentDate;

  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );
  return differenceInDays <= days;
};

const getAvl = (part, defaultStock) => {
  const transit = {
    productsInTransitInLessTreeWeeks: 0,
    deliveryDateUnderThreeWeeks: "",
    productsInTransitInOverTreeWeeks: 0,
    deliveryDateOverThreeWeeks: "",
    numberOfProductsInTransit: 0,
  };

  const location = part.available.find(
    ({ location }) => location === defaultStock
  );

  const deliveries = part.deliveries[defaultStock];

  if (deliveries && Object.keys(deliveries).length > 0) {
    deliveries.forEach((obj) => {
      const deliveryTimeCheck = isDateDifferenceGreaterThan(obj, 20);
      transit.numberOfProductsInTransit += obj.quantity;

      if (deliveryTimeCheck) {
        transit.productsInTransitInLessTreeWeeks += obj.quantity;
        transit.deliveryDateUnderThreeWeeks = obj.eta;
      } else {
        transit.productsInTransitInOverTreeWeeks += obj.quantity;
        transit.deliveryDateOverThreeWeeks = obj.eta;
      }
    });
  }

  const manufacturer = part.available.find(
    ({ location }) => location === "manufacturer"
  );

  return {
    location: (location && location.quantityavailable) || 0,
    manufacturer:
      (manufacturer &&
        location.specialbackorder &&
        manufacturer.quantityavailable) ||
      0,
    intransit: location.intransit || transit.numberOfProductsInTransit || 0,
    transit,
    max:
      ((location && location.quantityavailable) || 0) +
      ((manufacturer &&
        location.specialbackorder &&
        manufacturer.quantityavailable) ||
        0) +
      (location.intransit || transit.numberOfProductsInTransit || 0),
  };
};

const isSpecialOrder = (part, defaultStock) => {
  const candidate = part.available.find(
    ({ location }) => location === defaultStock
  );
  return candidate && candidate.stockcategory !== "stockeditem";
};

const ProductDetails = ({ part, addHandler, buttonText, enquiryHandler }) => {
  const { basket } = useAppContext();
  const { cookiesConsentCtx } = useConsentContext();

  const [quantity, setQuantity] = useState(1);
  const [basketQuantity, setBasketQuantity] = useState(0);
  const partIdRef = useRef(part.id);
  const defaultStockLocation = part.defaultStockLocation;

  useEffect(() => {
    const found = basket?.transactionlines.find(
      (obj) => obj.item._id === partIdRef.current
    );
    setBasketQuantity(found ? found.quantity : 0);
  }, [basket]);

  return (
    <section>
      <DetailsHeader text={part.description + " " + part.name} />
      <PurchaseArea>
        <Price
          vat={getPrice(part, defaultStockLocation)}
          isVatIncluded={isVatIncluded(part, defaultStockLocation)}
          promo={part.price.prices[defaultStockLocation].promotion}
          baseprice={part.price.baseprice}
        />
        {cookiesConsentCtx.accepted ? (
          <Buy
            buttonText={buttonText}
            availability={getAvl(part, defaultStockLocation)}
            record={part.id}
            addHandler={addHandler}
            defaultStockLocation={defaultStockLocation}
            enquiryHandler={enquiryHandler}
            max={getAvl(part, defaultStockLocation).max}
            changeQuantity={(e) => setQuantity(e)}
          />
        ) : (
          <NoCookieConsent>
            In order to purchase, please refresh the page and accept the cookie
            policy
          </NoCookieConsent>
        )}
      </PurchaseArea>
      <Availability
        availability={getAvl(part, defaultStockLocation)}
        quantity={quantity + basketQuantity}
      />
      {isSpecialOrder(part, defaultStockLocation) && (
        <SpecialOrder>Special Order Item</SpecialOrder>
      )}
    </section>
  );
};

export default ProductDetails;

const PurchaseArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  flex-direction: column;

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }
`;

const NoCookieConsent = styled.div`
  border: 2px solid ${PRIMARY};
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
`;

const SpecialOrder = styled.div`
  font-family: "Open Sans", sans-serif;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #333333;
`;
